import { Button, Card, Col, Form, Input, Modal, Row, Select } from 'antd';
import { ResponseDataType, deleteRequest, getRequest, postRequest, patchRequest } from 'api';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PermIdentityOutlined from '@mui/icons-material/PermIdentityOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import styles from './info.module.css';

interface CustomerInfoType {
  email: string;
  phoneNumber: string;
  customerId: number;
  schools: any[];
  admPassword: string;
}
interface FormValueType {
  email: string;
  phoneNumber: string;
  customerId: number;
  schools: any[];
  role: number;
  status: number;
  admPassword: string;
}

const statusList = ['', 'Đang làm việc', 'Nghỉ việc'];
const typeList = ['', 'Admin trường', 'Hiệu trưởng', 'Kế toán', 'Giáo viên'];

export const AccountInfo = () => {
  const schoolId = localStorage.getItem('schoolId');
  const { accountId } = useParams();
  // console.log('UseParams', useParams());

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoType>();

  useEffect(() => {
    const GetCustomerInfo = async () => {
      const response: ResponseDataType<CustomerInfoType> = await getRequest(
        `/cms/customer/${accountId}`
      );
      // console.log('response', response);
      if (response.code === 200) {
        setCustomerInfo(response.info);
      } else {
        toast.error(response.msg);
        // navigate('/account-list');
      }
    };
    GetCustomerInfo();
  }, [accountId, navigate]);

  const UpdateCustomerInfo = (value: FormValueType) => {
    console.log('value', value);

    const formData = {
      "customerId": accountId,
      "email": customerInfo?.email,
      "phoneNumber": customerInfo?.phoneNumber,
      "password": value.admPassword,
      "schools": [
        {
          "schoolId": customerInfo?.schools[0].schoolId,
          "type": value.role ? value.role : customerInfo?.schools[0].type,
          "status": value.status ? value.status : customerInfo?.schools[0].status
        }
      ]
    };
    console.log('formData', formData);


    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận cập nhật thông tin tài khoản?',
      async onOk() {
        const response: ResponseDataType<CustomerInfoType> = await patchRequest(
          '/cms/admin/customer',
          formData,
          'json'
        );
        console.log('response', response);
        if (response) {
          if (response.code === 200) {
            toast.success('Cập nhật tài khoản thành công!');
            navigate('/account-list');
          } else {
            toast.error('Cập nhật tài khoản thất bại!');
            // Change tab in case of error
          }
        } else {
          toast.error('Cập nhật tài khoản thất bại!');
        }
      },
    });
  };

  const DeleteCustomer = () => {
    Modal.confirm({
      style: { fontFamily: 'Quicksand' },
      title: 'Xác nhận xóa tài khoản?',
      async onOk() {
        const response: ResponseDataType<string> = await deleteRequest(
          `/cms/admin/customer/${accountId}`
        );
        // console.log('response', response);
        if (response) {
          if (response.code === 200) {
            toast.success('Xóa tài khoản thành công!');
            navigate('/account-list');
          } else {
            toast.error('Xóa tài khoản thất bại!');
            // Change tab in case of error
          }
        } else {
          toast.error('Xóa tài khoản thất bại!');
        }
      },
    });
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={8} >
          <Card title='Chi tiết tài khoản' className={styles.customer_info_card}>
            <Form form={form} onFinish={UpdateCustomerInfo}>
              <Row>
                <Col span={8}>
                  <div
                    className={styles.card_content_label}
                    style={{ marginTop: 0 }}
                  >
                    <EmailOutlinedIcon />
                    &nbsp;&nbsp;Email:
                  </div>
                </Col>
                <Col
                  span={16}
                  style={{ width: '70%', color: '#009BE5', fontWeight: '800' }}
                >
                  <Form.Item name='email'>
                    {customerInfo?.email}
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginTop: 0 }}>
                <Col span={8}>
                  <div
                    className={styles.card_content_label}
                    style={{ marginTop: 0 }}
                  >
                    <PhoneOutlinedIcon />
                    &nbsp;&nbsp;Phone:
                  </div>
                </Col>
                <Col
                  span={16}
                  style={{ width: '70%', color: '#009BE5', fontWeight: '800' }}
                >
                  <Form.Item name='phoneNumber'>
                    {customerInfo?.phoneNumber}
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col span={8}>
                  <div className={styles.card_content_label}>
                    <LockOutlinedIcon />
                    &nbsp;&nbsp;Mật khẩu
                  </div>
                </Col>
                <Col span={14}>
                  <Form.Item name='admPassword'>
                    <Input.Password
                      // prefix={
                      //   <PermIdentityOutlined
                      //   // className={popupStyles.form_input_prefix_icon}
                      //   />
                      // }
                      placeholder='Mật khẩu'
                    // className={popupStyles.form_input}
                    />

                  </Form.Item>
                </Col>
              </Row>
              <Row style={{
                marginTop: 0,
                fontWeight: 'bold',
                color: 'black',
              }}>
                <Col span={8}>
                  <div className={styles.card_content_label}>
                    <LockOutlinedIcon />
                    &nbsp;&nbsp;Xác nhận
                  </div>
                </Col>
                <Col span={14}>
                  <Form.Item
                    name='confirmPassword'
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) return Promise.reject('Cần xác nhận mật khẩu!');

                          if (value !== getFieldValue('admPassword')) {
                            return Promise.reject('Mật khẩu xác nhận không khớp');
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Input.Password
                      // prefix={
                      //   <PermIdentityOutlined
                      //   // className={popupStyles.form_input_prefix_icon}
                      //   />
                      // }
                      placeholder='Xác nhận mật khẩu'
                    // className={popupStyles.form_input}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Button type='primary' htmlType='submit' style={{ marginTop: 15 }}>
                Cập nhật tài khoản
              </Button>
            </Form>
          </Card>
        </Col>
        <Col span={8} >
          <Card title='Thông tin trường' className={styles.customer_info_card}>
            <Form form={form} onFinish={UpdateCustomerInfo}>
              <Row>
                <Col span={8}>
                  <div
                    className={styles.card_content_label}
                    style={{ marginTop: 0 }}
                  >
                    <SchoolOutlinedIcon />
                    &nbsp;&nbsp;Tên trường
                  </div>
                </Col>
                <Col
                  span={16}
                  style={{ width: '70%', color: '#009BE5', fontWeight: '800' }}
                >
                  <Form.Item name='schoolName' className='.box'>
                    {customerInfo?.schools[0].schoolName}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div
                    className={styles.card_content_label}
                    style={{ marginTop: 0 }}
                  >
                    <AccountBalanceOutlinedIcon />
                    &nbsp;&nbsp;MST
                  </div>
                </Col>
                <Col
                  span={16}
                  style={{ width: '70%', color: '#009BE5', fontWeight: '800' }}
                >
                  <Form.Item name='email' className='.box'>
                    {customerInfo?.schools[0].mst}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <div
                    className={styles.card_content_label}
                    style={{ marginTop: 0 }}
                  >
                    <PermIdentityOutlined />
                    &nbsp;&nbsp;Vị trí
                  </div>
                </Col>
                <Col
                  span={16}
                  style={{ width: '70%', color: '#009BE5', fontWeight: '800' }}
                >
                  <Form.Item name='email' className='.box'>
                    {typeList[customerInfo?.schools[0].type ? customerInfo.schools[0].type : 1]}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      <div className={styles.delete_customer_button} onClick={DeleteCustomer}>
        Xóa tài khoản
      </div>
    </div>
  );
};
