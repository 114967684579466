import { Card, CircularProgress, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import { getRequest, ResponseDataType } from 'api';
import React, { useEffect, useState } from 'react';
import styles from './home.module.css';

const card_sx = {
  color: '#141D49',
  padding: '10px',
  width: '250px',
  cursor: 'pointer',
};

const circular_progress_sx = {
  color: '#141D49',
};

interface GeneralInfoDataType {
  sumAccountants: number;
  sumTeachers: number;
  sumParents: number;
  sumStudents: number;
  sumSchools: number;
  sumInvoices: number;
}

export const Home = () => {
  // Update the state type to GeneralInfoDataType
  const [generalInfo, setGeneralInfo] = useState<GeneralInfoDataType | null>(null);

  useEffect(() => {
    const url = `/cms/admin/customer/home`;

    const fetchData = async () => {
      const response: ResponseDataType<GeneralInfoDataType> = await getRequest(url);
      // console.log('response', response);
      if (response) {
        if (response.code === 200 && response.info) {
          setGeneralInfo(response.info);
        } else {
          console.log(response.msg);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <Grid container spacing={5}>
      <Grid item>
        <Link style={{ textDecoration: 'none' }} to='/school-list'>
          <Card sx={card_sx}>
            <p className={styles.card_title}>Trường</p>
            <p className={styles.card_number}>
              {generalInfo ? generalInfo.sumSchools : <CircularProgress sx={circular_progress_sx} />}
            </p>
            <Grid container justifyContent='flex-end'>
              <DomainOutlinedIcon />
            </Grid>
          </Card>
        </Link>
      </Grid>
      <Grid item>
        <Link style={{ textDecoration: 'none' }} to=''>
          <Card sx={card_sx}>
            <p className={styles.card_title}>Giáo viên</p>
            <p className={styles.card_number}>
              {generalInfo ? generalInfo.sumTeachers : <CircularProgress sx={circular_progress_sx} />}
            </p>
            <Grid container justifyContent='flex-end'>
              <PeopleAltOutlinedIcon />
            </Grid>
          </Card>
        </Link>
      </Grid>
      <Grid item>
        <Link style={{ textDecoration: 'none' }} to='/'>
          <Card sx={card_sx}>
            <p className={styles.card_title}>Phụ huynh</p>
            <p className={styles.card_number}>
              {generalInfo ? generalInfo.sumParents : <CircularProgress sx={circular_progress_sx} />}
            </p>
            <Grid container justifyContent='flex-end'>
              <PeopleAltOutlinedIcon />
            </Grid>
          </Card>
        </Link>
      </Grid>
      <Grid item>
        <Link style={{ textDecoration: 'none' }} to='/'>
          <Card sx={card_sx}>
            <p className={styles.card_title}>Học sinh</p>
            <p className={styles.card_number}>
              {generalInfo ? generalInfo.sumStudents : <CircularProgress sx={circular_progress_sx} />}
            </p>
            <Grid container justifyContent='flex-end'>
              <PeopleAltOutlinedIcon />
            </Grid>
          </Card>
        </Link>
      </Grid>
    </Grid>
  );
};
