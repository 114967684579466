import React, { useEffect, useState } from 'react';
import { Table, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { getRequest, ResponseDataType } from 'api';

interface CustomerDataType {
  customerId: number;
  email: string;
  phoneNumber: string;
}

const columns: TableProps<CustomerDataType>['columns'] = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: '110px',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    width: '140px',
  },
  {
    title: 'Hành động',
    dataIndex: 'customerId',
    key: 'action',
    width: '140px',
    render: (customerId) => (
      <Link to={`/account-list/account-info/${customerId}`}>Chi tiết</Link>
    ),
  },
];

export const AccountList = () => {
  const [data, setData] = useState<CustomerDataType[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    const queryParams = new URLSearchParams({
      page: page.toString(),
      perPage: perPage.toString(),
    });
    const url = `/cms/customer?${queryParams.toString()}`;

    const fetchData = async () => {
      const response: ResponseDataType<{ list: CustomerDataType[]; total: number }> = await getRequest(url);
      console.log(response);
      if (response && response.code === 200) {
        setData(response.info?.list || []);
        setTotal(response.info?.total || 0);
      } else {
        console.log(response.msg);
      }
    };
    fetchData();
  }, [page, perPage]);

  const handleTableChange = (pagination: any) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="customerId"
        pagination={{
          current: page,
          pageSize: perPage,
          total: total,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};
