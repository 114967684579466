import { Grid } from '@mui/material';
import { Card, Avatar } from 'antd';
import styles from '../../school.module.css';
import { AddCircleOutlineOutlined, MoreVert } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Popup } from 'Components';
import { CreateSchoolAdminPopup } from '../../Components';
import { useParams, Link } from 'react-router-dom';
import { getRequest, ResponseDataType } from 'api';

interface CustomerDataType {
  customerId: number;
  email: string;
  phoneNumber: string;
  name: string | null;
  avatar: string | null;
}

export const SchoolAdminList = () => {
  const { schoolId } = useParams();
  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState(false);
  const [data, setData] = useState<CustomerDataType[]>([]);

  const page = 1;
  const perPage = 10;

  useEffect(() => {
    const queryParams = new URLSearchParams({
      schoolId: schoolId as string,
      page: page.toString(),
      perPage: perPage.toString(),
    });
    const url = `/cms/customer/get-list-admins-by-school?${queryParams.toString()}`;

    const fetchData = async () => {
      const response: ResponseDataType<CustomerDataType[]> = await getRequest(url);
      console.log('response', response);
      if (response) {
        if (response.code === 200) {
          setData(response.info || []);
          console.log('Admin list:', response.info);
        } else {
          console.log('Error message:', response.msg);
        }
      }
    };
    fetchData();
  }, [schoolId]);

  return (
    <Grid container spacing={5} marginTop={-2}>
      <Grid item xs={10}>
        <Card
          title="Admin trường"
          className={styles.list_card}
          extra={
            <div
              style={{ borderRadius: '40%', cursor: 'pointer', lineHeight: 0 }}
              onClick={() => setIsOpenCreatePopup(true)}
            >
              <AddCircleOutlineOutlined style={{ fontSize: 30 }} />
            </div>
          }
        >
          {data.length > 0 ? (
            data.map((admin) => (
              <div key={admin.customerId} className={styles.adminCard}>
                <Avatar src={admin.avatar || '/images/avatar.png'} /> {/* Sử dụng ảnh mặc định nếu avatar null hoặc trống */}
                <div className={styles.adminInfo}>
                  <div className={styles.adminName}>{admin.name || 'No Name'}</div> {/* Hiển thị 'No Name' nếu name null */}
                  <div className={styles.adminEmail}>{admin.email}</div>
                </div>
                <Link to={`/account-list/account-info/${admin.customerId}`} className={styles.adminActions}>
                  <MoreVert />
                </Link>
              </div>
            ))
          ) : (
            <div>Không có dữ liệu</div>
          )}
        </Card>
      </Grid>
      {isOpenCreatePopup && (
        <Popup close={() => setIsOpenCreatePopup(false)}>
          <CreateSchoolAdminPopup
            close={() => setIsOpenCreatePopup(false)}
            schoolId={schoolId as string}
            getAdminList={() => { }}
          />
        </Popup>
      )}
    </Grid>
  );
};
